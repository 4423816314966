import React from "react";

const Product = () => {
	const networkingSolution = {
		filter: "networking-solution",
		name: "Networking Solution",
	};
	const collaboration = {
		filter: "collaboration",
		name: "Collaboration",
	};
	const dataCenterServerStorage = {
		filter: "data-centre-server-storage",
		name: "Data Centre & Server & Storage",
	};
	const dataSecurity = {
		filter: "data-security",
		name: "Data Security",
	};
	const firePASystemEPBX = {
		filter: "fire-pa-system-epbx",
		name: "Fire, PA System & EPBX",
	};
	const surveillancePowerSolution = {
		filter: "surveillance-power-solution",
		name: "Surveillance & Power Solution",
	};
	const computing = {
		filter: "computing",
		name: "Computing",
	};
	const dppso = {
		filter: "dppso",
		name: "Display, Printing, Peripherals Software & Others",
	};

	let products = [
		{ id: 1, name: "Apc", imgSrc: "assets/img/products/apc.png", filters: [surveillancePowerSolution] },
		{ id: 2, name: "Aruba", imgSrc: "assets/img/products/aruba.png", filters: [networkingSolution] },
		{ id: 3, name: "Asus", imgSrc: "assets/img/products/asus.png", filters: [computing] },
		{ id: 4, name: "Avaya", imgSrc: "assets/img/products/avaya.png", filters: [collaboration] },
		{ id: 5, name: "Axis", imgSrc: "assets/img/products/axis.png", filters: [surveillancePowerSolution] },
		{ id: 6, name: "Benq", imgSrc: "assets/img/products/benq.png", filters: [dppso] },
		{ id: 7, name: "Bosch", imgSrc: "assets/img/products/bosch.png", filters: [firePASystemEPBX, surveillancePowerSolution] },
		{ id: 8, name: "Brother", imgSrc: "assets/img/products/brother.png", filters: [dppso] },
		{ id: 9, name: "Canon", imgSrc: "assets/img/products/canon.png", filters: [dppso] },
		{
			id: 10,
			name: "Cisco",
			imgSrc: "assets/img/products/cisco.png",
			filters: [networkingSolution, collaboration, dataCenterServerStorage, dataSecurity],
		},
		{ id: 11, name: "Cp", imgSrc: "assets/img/products/cp.png", filters: [dataSecurity] },
		// { id: 12, name: "Cpplus", imgSrc: "assets/img/products/cpplus.png", filters: [surveillancePowerSolution] },
		{ id: 12, name: "Cyberpower", imgSrc: "assets/img/products/cyberpower.png", filters: [surveillancePowerSolution] },
		{ id: 13, name: "Dahua", imgSrc: "assets/img/products/dahua.png", filters: [surveillancePowerSolution] },
		{ id: 14, name: "Dell", imgSrc: "assets/img/products/dell.png", filters: [dataCenterServerStorage, computing] },
		{ id: 15, name: "Dlink", imgSrc: "assets/img/products/dlink.png", filters: [networkingSolution] },
		// { id: 16, name: "Finolex", imgSrc: "assets/img/products/finolex.png", filters: [networkingSolution] },

		{ id: 16, name: "Firelight", imgSrc: "assets/img/products/firelight.png", filters: [firePASystemEPBX] },
		{ id: 17, name: "Fortinet", imgSrc: "assets/img/products/fortinet.png", filters: [dataSecurity] },
		{ id: 18, name: "Grandstream", imgSrc: "assets/img/products/grandstream.png", filters: [collaboration, firePASystemEPBX] },
		{ id: 19, name: "Hifocus", imgSrc: "assets/img/products/hifocus.png", filters: [surveillancePowerSolution] },
		{ id: 20, name: "Hikvision", imgSrc: "assets/img/products/hikvision.png", filters: [surveillancePowerSolution] },
		{ id: 21, name: "Honeywell", imgSrc: "assets/img/products/honeywell.png", filters: [networkingSolution, firePASystemEPBX, surveillancePowerSolution] },
		{ id: 22, name: "Hp", imgSrc: "assets/img/products/hp.png", filters: [dataCenterServerStorage, computing, dppso] },
		{ id: 23, name: "Intel", imgSrc: "assets/img/products/intel.png", filters: [dppso] },
		// { id 26, name: "Jbl", imgSrc: "assets/img/products/jbl.png", filters: [firePASystemEPBX] },
		{ id: 24, name: "Legrand", imgSrc: "assets/img/products/legrand.png", filters: [networkingSolution] },
		{ id: 25, name: "Lenovo", imgSrc: "assets/img/products/lenovo.png", filters: [dataCenterServerStorage, computing] },
		{ id: 26, name: "Lg", imgSrc: "assets/img/products/lg.png", filters: [dppso] },
		{ id: 27, name: "Lifesize", imgSrc: "assets/img/products/lifesize.png", filters: [collaboration] },
		{ id: 28, name: "Logitech", imgSrc: "assets/img/products/logitech.png", filters: [dppso] },
		{ id: 29, name: "Matrix", imgSrc: "assets/img/products/matrix.png", filters: [firePASystemEPBX] },
		{ id: 30, name: "Molex", imgSrc: "assets/img/products/molex.png", filters: [networkingSolution] },
		{ id: 31, name: "Morley.png", imgSrc: "assets/img/products/morley-ias.png", filters: [firePASystemEPBX] },
		{ id: 32, name: "Ms", imgSrc: "assets/img/products/ms.png", filters: [dppso] },
		{ id: 33, name: "Nec", imgSrc: "assets/img/products/nec.png", filters: [firePASystemEPBX] },
		{ id: 34, name: "Netgear", imgSrc: "assets/img/products/netgear.png", filters: [networkingSolution] },
		{ id: 35, name: "Notifier", imgSrc: "assets/img/products/notifier.png", filters: [firePASystemEPBX] },
		{ id: 36, name: "Paloalto", imgSrc: "assets/img/products/paloalto.png", filters: [dataSecurity] },
		{ id: 37, name: "Pelco", imgSrc: "assets/img/products/pelco.png", filters: [surveillancePowerSolution] },
		{ id: 38, name: "Polycom", imgSrc: "assets/img/products/polycom.png", filters: [collaboration] },
		{ id: 39, name: "Qnap", imgSrc: "assets/img/products/qnap.png", filters: [dataCenterServerStorage] },
		{ id: 40, name: "Seagate", imgSrc: "assets/img/products/seagate.png", filters: [dppso] },
		{ id: 41, name: "Siemens", imgSrc: "assets/img/products/siemens.png", filters: [firePASystemEPBX] },
		{ id: 42, name: "Sophos", imgSrc: "assets/img/products/sophos.png", filters: [dataSecurity] },
		{ id: 43, name: "Synology", imgSrc: "assets/img/products/synology.png", filters: [dataCenterServerStorage] },
		// { id 45, name: "Tyco", imgSrc: "assets/img/products/tyco.png", filters: [networkingSolution, firePASystemEPBX] },
		// { id 48, name: "Viewsonic", imgSrc: "assets/img/products/viewsonic.png", filters: [dppso] },
		// { id: 49, name: "Wardwiz", imgSrc: "assets/img/products/wardwiz.png", filters: [dppso] },
		{ id: 44, name: "Windows", imgSrc: "assets/img/products/windows.png", filters: [dppso] },
		{ id: 45, name: "Ws", imgSrc: "assets/img/products/ws.png", filters: [dppso] },
		{ id: 46, name: "Zebra", imgSrc: "assets/img/products/zebra.png", filters: [dppso] },
	];

	return (
		<section id="products" className="portfolio" data-aos="fade-up">
			<div className="container">
				<div className="section-header">
					<h2>Our Products</h2>
				</div>
			</div>

			<div className="container-fluid" data-aos="fade-up" data-aos-delay="200">
				<div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">
					<ul className="portfolio-flters">
						<li data-filter="*" className="filter-active">
							All
						</li>
						<li data-filter=".networking-solution">Networking Solution</li>
						<li data-filter=".collaboration">Collaboration</li>
						<li data-filter=".data-centre-server-storage">Data Centre & Server & Storage</li>
						<li data-filter=".data-security">Data Security</li>
						<br />
						<li data-filter=".fire-pa-system-epbx">Fire, PA System & EPBX</li>
						<li data-filter=".surveillance-power-solution">Surveillance & Power Solution</li>
						<br />
						<li data-filter=".computing">Computing</li>
						<li data-filter=".dppso">Display, Printing, Peripherals Software & Others</li>
					</ul>

					<div className="row portfolio-container">
						{products.map((product) => {
							return (
								<div
									className={"col-xl-2 col-lg-2 col-md-3 col-6 portfolio-item " + product.filters.map((f) => f.filter).join(" ")}
									key={product.id}
								>
									<img src={product.imgSrc} className="img-fluid" alt="" style={{ scale: "0.75" }} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Product;
