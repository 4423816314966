import React from "react";
import AboutUs from "./lib/AboutUs";
import Client from "./lib/Client";
import Contact from "./lib/Contact";
import Product from "./lib/Product";
import Service from "./lib/Service";

const Main = () => {
	return (
		<main id="main">
			<AboutUs />
			<Service />
			<Product />
			{/* <Client /> */}
			<Contact />
		</main>
	);
};

export default Main;
