import React from "react";

const AboutUs = () => {
	return (
		<section id="about" className="about">
			<div className="container" data-aos="fade-up">
				<div className="section-header">
					<h2>About Us</h2>
					<p>
						The "Computer" is the most technologically efficient machine ever invented by humans. We connect them; We, Zipsol IT Soltuion PVT. LTD.,
						have a huge experience of client servicing for more than 20 years in Distribution & Supply Chain, and would like to take this
						opportunity to introduce ourselves, Zipsol IT Soltuion PVT. LTD., as a Solution Provider Active & Passive Networking, Video
						Communication, Security & Video Surveillance in the Active & Solutions, Power Solutions, and other IT Solutions in the state
						of Gujarat for more than 15 years. We serve as a system integrator for small & Medium Enterprises, Corporate and Govt.
						Clients.
					</p>
				</div>

				<div className="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">
					<div className="col-lg-5">
						<div className="about-img">
							<img src="assets/img/about.jpg" className="img-fluid" alt="" />
						</div>
					</div>

					<div className="col-lg-7">
						{/* <h3 className="pt-0 pt-lg-5">Neque officiis dolore maiores et exercitationem quae est seda lidera pat claero</h3> */}

						<ul className="nav nav-pills mb-3">
							<li>
								<span className="nav-link active" data-bs-toggle="pill" href="#">
									Our Vision
								</span>
							</li>
						</ul>

						<div className="tab-content">
							<div className="tab-pane fade show active">
								<p className="fst-italic">
									“To be the fastest growing, transformative organization with integrity & customer delight at its core.”
								</p>
							</div>
						</div>

						<ul className="nav nav-pills mb-3">
							<li>
								<span className="nav-link active" data-bs-toggle="pill">
									Our Mission
								</span>
							</li>
						</ul>

						<div className="tab-content">
							<div className="tab-pane fade show active">
								<p className="fst-italic">
									“Our Mission at Zipsol IT Soltuion PVT. LTD. is to treat equip our clients with top class IT, Non-IT products and provide all
									the services under one roof, while maintaining honesty, integrity and providing excellent customer services.”
								</p>
							</div>
						</div>

						<ul className="nav nav-pills mb-3">
							<li>
								<span className="nav-link active" data-bs-toggle="pill" href="#">
									Our Core Values
								</span>
							</li>
						</ul>

						<div className="tab-content">
							<div className="tab-pane fade show active">
								<div className="d-flex align-items-center mt-4">
									<i className="bi bi-arrow-right"></i>
									<h4>Integrity:</h4>
								</div>
								<p>We build trust and deliver what we promise to our people and our clients.</p>
								<div className="d-flex align-items-center mt-4">
									<i className="bi bi-arrow-right"></i>
									<h4>Quality:</h4>
								</div>
								<p>We ensure high quality standard deliverables to our clients.</p>
								<div className="d-flex align-items-center mt-4">
									<i className="bi bi-arrow-right"></i>
									<h4>Collaboration:</h4>
								</div>
								<p>We work together with our partners to achieve long term mutual growth.</p>
								<div className="d-flex align-items-center mt-4">
									<i className="bi bi-arrow-right"></i>
									<h4>Respect:</h4>
								</div>
								<p>We create an environment where each person feels valued and is treated with dignity.</p>
								<div className="d-flex align-items-center mt-4">
									<i className="bi bi-arrow-right"></i>
									<h4>Innovation:</h4>
								</div>
								<p>We innovate cost effective solutions to stay ahead of issues facing businesses and industry.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
