import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  let formRef = useRef();
  let nameRef = useRef();
  let emailRef = useRef();
  let messageRef = useRef();
  var [isMessageSent, setIsMessageSent] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm("service_3fr4j1s", "template_bb25dck", formRef.current, "vzqnh6S4aNtzLlhlf")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setIsMessageSent(true);
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-header">
          <h2>Contact Us</h2>
          <p>
            The Most <b>Technologically</b> Efficient Machine That <b>Human</b>{" "}
            Ever Invented is <b>"Computer"</b>
          </p>
          <p>
            We <b>Connect</b> Them Together - <b>Zipsol IT Solution PVT. LTD.</b>
          </p>
        </div>
      </div>

      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.6047568943036!2d72.84343127460558!3d21.207854481604492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fff9750360b%3A0xa4def5cc1875fd12!2sZipsol%20It%20Solution%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1693638814549!5m2!1sen!2sin"></iframe>

      </div>

      <div className="container">
        <div className="row gy-5 gx-lg-5">
          <div className="col-lg-4">
            <div className="info">
              <h3>Get in touch</h3>
              <p>
                Don't hesitate to give us a call or send us a contact form
                message.
              </p>

              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>
                  D-119 Yash Plaza,<br></br>
                  Gitanjali, Varachha,<br></br> 
                  Surat-395006<br></br>
                  Gujarat India
                  </p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>zipsolitsolution@gmail.com</p>
                </div>
              </div>

              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>
                    +91 83470 11156
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <form
              ref={formRef}
              onSubmit={handleFormSubmit}
              method="post"
              className="php-email-form"
            >
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    name="from_name"
                    ref={nameRef}
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    ref={emailRef}
                    name="from_email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  name="message"
                  ref={messageRef}
                  placeholder="Message"
                  style={{ resize: "none", height:"350px" }}
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div
                  className="sent-message"
                  style={{ display: isMessageSent ? "block" : "none" }}
                >
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
