const Header = () => {
	return (
		<header id="header" className="header fixed-top">
			<div className="container-fluid d-flex align-items-center justify-content-between px-lg-5">
				<a href="index.html" className="logo d-flex align-items-center scrollto me-auto me-lg-0">
					<img src="assets/img/zipsol.jpg" alt="Logo" />
				</a>

				<nav id="navbar" className="navbar">
					<ul>
						<li>
							<a className="nav-link scrollto" href="index.html#home">
								Home
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#about">
								About Us
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#services">
								Our Services
							</a>
						</li>
						<li>
							<a className="nav-link scrollto" href="index.html#products">
								Our Product
							</a>
						</li>
						{/* <li>
							<a className="nav-link scrollto" href="index.html#clients">
								Our Client
							</a>
						</li> */}
						<li>
							<a className="nav-link scrollto" href="index.html#contact">
								Contact Us
							</a>
						</li>
					</ul>
					<i className="bi bi-list mobile-nav-toggle d-none"></i>
				</nav>
			</div>
		</header>
	);
};

export default Header;
